
import './App.css';
import HeaderComponent from './Components/HeaderComponent';
import MainComponent from './Components/MainComponent';
import FooterComponent from './Components/FooterComponent';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
function App() {
  useEffect(()=>{
    AOS.init();
  },[])
  return (
    <>
      <HeaderComponent/>
      <MainComponent/>
      <FooterComponent/>
    </>
  );
}

export default App;
