import React from "react";

const FooterComponent = () => {
  return (
    <>
      <footer id="footer" className="footer light-background">
        <div className="container footer-top">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6 footer-about">
              <a href="index.html" className="logo d-flex align-items-center">
                <span className="sitename">Medilab</span>
              </a>
              <div className="footer-contact pt-3">
                <p>A108 Adam Street</p>
                <p>New York, NY 535022</p>
                <p className="mt-3">
                  <strong>Phone:</strong> <span>+1 5589 55488 55</span>
                </p>
                <p>
                  <strong>Email:</strong> <span>info@example.com</span>
                </p>
              </div>
              <div className="social-links d-flex mt-4">
                <a href>
                  <i className="bi bi-twitter-x" />
                </a>
                <a href>
                  <i className="bi bi-facebook" />
                </a>
                <a href>
                  <i className="bi bi-instagram" />
                </a>
                <a href>
                  <i className="bi bi-linkedin" />
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a href="/" alt="">
                    Home
                  </a>
                </li>
                <li>
                  <a href="/" alt="">
                    About us
                  </a>
                </li>
                <li>
                  <a href="/" alt="">
                    Services
                  </a>
                </li>
                <li>
                  <a href="/" alt="">
                    Terms of service
                  </a>
                </li>
                <li>
                  <a href="/" alt="">
                    Privacy policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <a href="#" alt="">
                    Web Design
                  </a>
                </li>
                <li>
                  <a href="#" alt="">
                    Web Development
                  </a>
                </li>
                <li>
                  <a href="#" alt="">
                    Product Management
                  </a>
                </li>
                <li>
                  <a href="#" alt="">
                    Marketing
                  </a>
                </li>
                <li>
                  <a href="#" alt="">
                    Graphic Design
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 footer-links">
              <h4>Hic solutasetp</h4>
              <ul>
                <li>
                  <a href="#" alt="">
                    Molestiae accusamus iure
                  </a>
                </li>
                <li>
                  <a href="#" alt="">
                    Excepturi dignissimos
                  </a>
                </li>
                <li>
                  <a href="#" alt="">
                    Suscipit distinctio
                  </a>
                </li>
                <li>
                  <a href="#" alt="">
                    Dilecta
                  </a>
                </li>
                <li>
                  <a href="#" alt="">
                    Sit quas consectetur
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 footer-links">
              <h4>Nobis illum</h4>
              <ul>
                <li>
                  <a href="#" alt="">
                    Ipsam
                  </a>
                </li>
                <li>
                  <a href="#" alt="">
                    Laudantium dolorum
                  </a>
                </li>
                <li>
                  <a href="#" alt="">
                    Dinera
                  </a>
                </li>
                <li>
                  <a href="#" alt="">
                    Trodelas
                  </a>
                </li>
                <li>
                  <a href="#" alt="">
                    Flexo
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container copyright text-center mt-4">
          <p>
            © <span>Copyright</span>{" "}
            <strong className="px-1 sitename">Medilab</strong>{" "}
            <span>All Rights Reserved</span>
          </p>
          <div className="credits">
            {/* All the links in the footer should remain intact. */}
            {/* You can delete the links only if you've purchased the pro version. */}
            {/* Licensing information: https://bootstrapmade.com/license/ */}
            {/* Purchase the pro version with working PHP/AJAX contact form: [buy-url] */}
            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
          </div>
        </div>
      </footer>
      <div>
        {/* Scroll Top */}
        <a
          href="#"
          id="scroll-top"
          className="scroll-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up-short" />
        </a>
        {/* Preloader */}
        {/* <div id="preloader" /> */}
      </div>
    </>
  );
};

export default FooterComponent;
