import React from "react";
import HeroPage from "../Pages/HeroPage";
import AboutPage from "../Pages/AboutPage";
import StartCountPage from "../Pages/StartCountPage";
import ServicePage from "../Pages/ServicePage";
import AppointmentPage from "../Pages/AppointmentPage";
import DepartmentPage from "../Pages/DepartmentPage";
import DoctorsPage from "../Pages/DoctorsPage";
import FAQPage from "../Pages/FAQPage";
import TestimonialPage from "../Pages/TestimonialPage";
import GalleryPage from "../Pages/GalleryPage";
import ContactPage from "../Pages/ContactPage";

const MainComponent = () => {
  return (
    <>
      <main className="main">
        <HeroPage/>
        <AboutPage/>
        <StartCountPage/>
        <ServicePage/>
        <AppointmentPage/>
        <DepartmentPage/>
        <DoctorsPage/>
        <FAQPage/>
        <TestimonialPage/>
        <GalleryPage/>
        <ContactPage/>
      </main>
    </>
  );
};

export default MainComponent;
