import React from "react";
// import AOS from 'aos';
// import 'aos/dist/aos.css';
const HeroPage = () => {
  // useEffect(()=>{
  //   AOS.init();
  // },[]);
  return (
    <>
      {/* Hero Section */}
      <section id="hero" className="hero section light-background">
        <img src='assets/img/hero-bg.jpg' alt=""  data-aos="fade-in"/>{/**data-aos="fade-in" */}
        <div className="container position-relative">
          <div
            className="welcome position-relative"
             data-aos="fade-down"
             data-aos-delay={100}
          >
            <h2>WELCOME TO MEDILAB</h2>
            <p>
              We are team of talented designers making websites with Bootstrap
            </p>
          </div>
          {/* End Welcome */}
          <div className="content row gy-4">
            <div className="col-lg-4 d-flex align-items-stretch">
              <div className="why-box" data-aos="zoom-out" data-aos-delay={200}>{/**data-aos="zoom-out" data-aos-delay={200} */}
                <h3>Why Choose Medilab?</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Duis aute irure dolor in reprehenderit Asperiores dolores sed
                  et. Tenetur quia eos. Autem tempore quibusdam vel
                  necessitatibus optio ad corporis.
                </p>
                <div className="text-center">
                  <a href="#about" className="more-btn">
                    <span>Learn More</span>{" "}
                    <i className="bi bi-chevron-right" />
                  </a>
                </div>
              </div>
            </div>
            {/* End Why Box */}
            <div className="col-lg-8 d-flex align-items-stretch">
              <div className="d-flex flex-column justify-content-center">
                <div className="row gy-4">
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div
                      className="icon-box"
                       data-aos="zoom-out"
                       data-aos-delay={300}
                    >
                      <i className="bi bi-clipboard-data" />
                      <h4>Corporis voluptates officia eiusmod</h4>
                      <p>
                        Consequuntur sunt aut quasi enim aliquam quae harum
                        pariatur laboris nisi ut aliquip
                      </p>
                    </div>
                  </div>
                  {/* End Icon Box */}
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div
                      className="icon-box"
                       data-aos="zoom-out"
                       data-aos-delay={400}
                    >
                      <i className="bi bi-gem" />
                      <h4>Ullamco laboris ladore pan</h4>
                      <p>
                        Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt
                      </p>
                    </div>
                  </div>
                  {/* End Icon Box */}
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div
                      className="icon-box"
                       data-aos="zoom-out"
                       data-aos-delay={500}
                    >
                      <i className="bi bi-inboxes" />
                      <h4>Labore consequatur incidid dolore</h4>
                      <p>
                        Aut suscipit aut cum nemo deleniti aut omnis. Doloribus
                        ut maiores omnis facere
                      </p>
                    </div>
                  </div>
                  {/* End Icon Box */}
                </div>
              </div>
            </div>
          </div>
          {/* End  Content*/}
        </div>
      </section>
      {/* /Hero Section */}
    </>
  );
};

export default HeroPage;
